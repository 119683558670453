<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="방지시설 보수내역"
        tableId="grid"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :usePaging="false"
        :hideBottom="false"
        :gridHeightAuto="param.isFullScreen"
        :columnSetting="false"
        :filtering="false"
        :isExcelDown="false"
        :editable="editable"
        selection="multiple"
        rowKey="envAirDailyResultPreventiveHistoryId"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="LBLADD" v-if="editable" icon="add" @btnClicked="addrow1" />
            <c-btn label="LBLREMOVE" v-if="editable" icon="remove" @btnClicked="removeRow1" />
            <c-btn label="LBLSAVE" v-if="editable" icon="save" @btnClicked="saveRow1" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'daily-air-report-03',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: null,
        envAirDailyResultId: '',
        isFullScreen: false,
        dailyResultDt: '',
        approvalStatusCd: null,
        approvalTarget: false,
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '60%',
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'envAirMstPreventiveName',
            field: 'envAirMstPreventiveName',
            label: '방지시설명',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'startDt',
            field: 'startDt',
            label: '보수시작일',
            align: 'center',
            type: 'date',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'endDt',
            field: 'endDt',
            label: '보수종료일',
            align: 'center',
            type: 'date',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '보수자',
            align: 'center',
            type: 'user',
            userId: 'userId',
            value: '',
            setHeader: true,
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '보수명세',
            align: 'left',
            type: 'text',
            sortable: true,
          },
        ],
        data: [],
      },
      listUrl: '',
      insertUrl: '',
      deleteUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  computed: {
    editable() {
      if (this.param.approvalTarget) {
        return true;
      } else if (this.param.approvalStatusCd !== null) {
        return false;
      } else {
        return this.$route.meta.editable
      }
    },
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.env.air.daily.preventive.history.url;
      this.insertUrl = transactionConfig.env.air.daily.preventive.history.save.url;
      this.deleteUrl = transactionConfig.env.air.daily.preventive.history.delete.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {dailyResultDt: this.param.dailyResultDt};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addrow1() {
      this.popupOptions.title = '대기방지시설 검색'; // 대기방지시설 검색
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/air/preventivePop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          this.grid.data.push({
            plantCd: this.param.plantCd,  // 사업장코드
            envAirMstPreventiveName: _item.envAirMstPreventiveName,  // 대기방지시설명
            envAirMstPreventiveId: _item.envAirMstPreventiveId,  // 대기방지시설 일련번호
            envAirDailyResultPreventiveHistoryId: uid(),  // 방지시설 보수내역 일련번호
            startDt: this.param.dailyResultDt,
            endDt: this.param.dailyResultDt,
            userId: '',
            remark: '',
            chgUserId: this.$store.getters.user.userId,
            regUserId: this.$store.getters.user.userId,
            editFlag: 'C'
          })
        })
      }
    },
    removeRow1() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveRow1() {
      let checkItem = ['startDt','endDt','userId']
      let isConti = true;
      this.$_.forEach(this.grid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '필수 입력값을 입력해 주세요. [보수시작일, 보수종료일, 보수자]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.insertUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getList();
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
  }
};
</script>
